





























































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DataObject, HeaderObject, SortValueObject } from './type'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import Skeleton from '../Skeleton/index.vue'

/**
 * Data Table
 *
 * `idIndex`
 *  for id item or id each row. I'm suggest, put the idIndex into first index of array.
 *
 * `headers`
 *  set the header with array string
 *  Example: ['No', 'Name', 'Age']
 *
 *  or you can set object in array
 *  Example: ['No', { title: 'Google', width: '168px' }, 'Age']
 *
 * `dataItems`
 *  if dataItems has value, you can set object in array
 *    Example: [
 *     [1, { img: 'google.png', title: 'Google', width: '168px' }, 1998],
 *     [2, { img: 'facebook.png', title: 'Facebook', width: '168px' }, 2004],
 *    ]
 *
 *  or you can set only array in array
 *    Example: [
 *     [2, 'Google', 1998],
 *     [2, 'Facebook', 2004],
 *    ]
 *
 *  if dataItems has empty value. you must set array empty.
 *    Example: []
 *
 *  If you want to custom the cell. You can customize in the parent file or where the file that calls this component.
 *  Example:
 *  <DataTable>
 *    <toggle-switch
 *      :id="data.id"
 *      :name="`status-${data.id}`"
 *      :is-checked="data.data"
 *      @click-me="$emit('set-status', data)"
 *    />
 *  </DataTable>
 *
 *  or use can see example in file `@/app/ui/views/Product/components/Table/TableVariant.vue` (if still exist haha)
 */

@Component({
  components: {
    ExpandIcon,
    AscendingIcon,
    Skeleton
  },
})
export default class DataTableV2 extends Vue {
  @Prop({ default: 99 }) private idIndex!: number
  @Prop({ default: () => [99] }) private hideIndex!: Array<number>
  @Prop({ default: () => [], required: true }) private headers!: Array<
    string[] | HeaderObject[]
  >
  @Prop({ default: () => [], required: true }) private dataItems!: Array<
    string[] | DataObject[] | DataObject[][]
  >
  @Prop({ default: () => [] }) private listHasCollapse!: Array<boolean[]>
  @Prop({ default: false }) private isLoading!: boolean
  @Prop({ default: () => [] }) private customName!: string[][]
  @Prop({ default: false }) private isHasAction!: boolean
  @Prop({ default: false }) private isHasCollapse!: boolean
  @Prop({ default: 'Title Collapse' }) private titleCollapse!: string
  @Prop({ default: '' }) private customClassTd!: string
  @Prop({ default: () => [] }) private requiredValue!: string[]
  @Prop({ default: 'btnExpand' }) private idBtnExpand!: string
  @Prop({ default: () => ({ name: '', direction: '' })}) private sort!: SortValueObject

  listExpanded = <number[]>[]

  private convertSlotName(text: string): string {
    return text
      .split(' ')
      .join('-')
      .replace(/[^a-zA-Z0-9-]+/g, '')
  }

  private onExpand(
    data: Array<string[] | DataObject[]>,
    index: number,
    indexRow: number
  ) {
    if (this.listExpanded.includes(index)) {
      this.listExpanded = this.listExpanded.filter(item => item !== index)
      this.$emit('on-expand', data, 'close')
      this.$emit('on-expand-v2', data, indexRow, 'close')
    } else {
      this.listExpanded.push(index)
      this.$emit('on-expand', data, 'open')
      this.$emit('on-expand-v2', data, indexRow, 'close')
    }
  }

  private hasSlot(slotName: string, index: number) {
    const name = this.convertSlotName((slotName[index] || '').toLowerCase())
    if (
      !!this.$slots[slotName[index]] ||
      !!this.$scopedSlots[name] ||
      !!this.$slots[index] ||
      !!this.$scopedSlots[index]
    ) {
      return true
    } else {
      return false
    }
  }

  private hasHeaderSlot(slotName: string, index: number) {
    const name = `header-${this.convertSlotName(
      (slotName[index] || '').toLowerCase()
    )}`
    if (
      !!this.$slots[slotName[index]] ||
      !!this.$scopedSlots[name] ||
      !!this.$slots[`header-${index}`] ||
      !!this.$scopedSlots[`header-${index}`]
    ) {
      return true
    } else {
      return false
    }
  }

  private isHasSlot(slotName: string) {
    return !!this.$slots[slotName] || !!this.$scopedSlots[slotName]
  }

  private hasName(slotName: string, index: number) {
    if (slotName[index]) {
      return this.convertSlotName(slotName[index].toLowerCase())
    } else {
      return index
    }
  }

  private hasHeaderName(slotName: string, index: number) {
    if (slotName[index]) {
      return `header-${this.convertSlotName(slotName[index].toLowerCase())}`
    } else {
      return `header-${index}`
    }
  }

  private getStyleItem(item: DataObject) {
    return { minWidth: item?.width, ...item?.customStyle }
  }

  public isSelectedSort(sort: string, direction: string): boolean {
   return this.sort.name === sort && this.sort.direction === direction
  }

  public onSort(sort: string): void {
    if (!this.sort.direction && !this.sort.name) {
      this.$emit('sort', <SortValueObject>{
        direction: 'ASC',
        name: sort
      })
    } else if (sort === this.sort.name) {
      if (this.sort.direction === 'ASC') {
        this.$emit('sort', <SortValueObject>{
          direction: 'DESC',
          name: sort
        })
      } else if (this.sort.direction === 'DESC') {
        this.$emit('sort', <SortValueObject>{
          direction: '',
          name: ''
        })
      }
    } else if (sort !== this.sort.name) {
      this.$emit('sort', <SortValueObject>{
        direction: 'ASC',
        name: sort
      })
    }
  }
}
